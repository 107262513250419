.portfolio__container{
    display: grid;
    grid-template-columns: repeat(3, 1fr) ;
    gap: 2.5rem;
}

.portfolio__item{
    background: transparent;
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    border-color: var(--color-primary);
}

/* .portfolio__item:hover{
    background: #7D6C0B;
} */

.portfolio__item-image{
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3{
    margin: 1.2rem 0 2rem;
}

.portfolio__item-cta{
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.dis{
    pointer-events: none;
}

@media screen and (max-width: 1024px) {
    .portfolio__container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

@media screen and (max-width: 600px) {
    .portfolio__container{
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}